.container {
  display: none;
  grid-template-columns: 40px 1fr 40px;
  grid-template-rows: 32px;
  gap: 10px;
  justify-items: center;
  align-items: center;
  margin-bottom: 8px;
  font-size: var(--font-6);
  color: var(--color-black);
}

.prefix,
.suffix {
}

.title {
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .container {
    display: grid;
  }
}
