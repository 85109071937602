.container {
  display: grid;
  place-items: center;
  margin-bottom: 100px;
}

.pcContent {
  display: block;
  margin-top: 10px;
}

.pcContent .tips {
  width: 550px;
  padding: 0;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  color: var(--color-black);
  text-align: center;
}

.tips a {
  color: inherit;
  text-decoration: underline;
}

.downloadButton {
  width: 229px;
  height: 62px;
  padding: 16px 32px;
  margin: 25px auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--color-black);
  border-radius: 8px;
}

.featureList {
  display: grid;
  list-style: none;
  margin: 25px auto;
  grid-template-rows: repeat(3, 86px);
  grid-template-columns: 125px 544px;
  gap: 10px 22px;
}

.featureList :is(dt, dd) {
  padding: 0;
  margin: 0;
  font-size: var(--font-6);
  color: var(--color-black);
}

.featureList dt {
  text-align: center;
  line-height: 86px;
  font-weight: 500;
  background-color: var(--color-white);
}

.featureList dd {
  padding-right: 40px;
  align-self: center;
  text-align: left;
  line-height: 26px;
  font-weight: 400;
}

.mobileContent {
  display: none;
  width: 326px;
  margin-top: 67px;
}

.mobileContent .tips {
  margin: 0;
  font-size: var(--font-4);
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
  color: rgb(0 0 0 / 50%);
}

.mobileContent .tips i {
  display: block;
  font-style: normal;
  font-size: 26px;
}

.mobileContent .tips strong {
  display: block;
  margin: 25px auto 20px;
  font-weight: 500;
  color: var(--color-black);
}

.screenshot {
  display: block;
  position: relative;
  padding: 0;
  margin: 50px auto;
  width: 286px;
  height: 193px;
  background: url(../../../../../resources/images/pick-to-kiwi.png) center
    center no-repeat;
  background-size: contain;
}

.screenshot::after {
  display: block;
  position: absolute;
  right: -8px;
  bottom: 36px;
  content: "";
  width: 32px;
  height: 32px;
  background: url(../../../../../resources/images/kiwi-icon.png) center center
    no-repeat #353537;
  background-size: 24px 25px;
}

@media screen and (max-width: 800px) {
  .pcContent {
    display: none;
  }

  .mobileContent {
    display: block;
  }
}
