.content {
  max-width: 560px;
  max-width: 65ch;
  margin: 10px auto 20px auto;
  padding-left: 20px;
  padding-right: 20px;
}
.policy__title {
  text-align: center;
}

.rte {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 1.4;
}

.rte ol,
.rte p,
.rte table,
.rte ul {
  margin-bottom: 25px;
}

.rte-setting a:not(.rte__image):not(.btn):not(.spr-summary-actions-newreview),
.rte a:not(.rte__image):not(.btn):not(.spr-summary-actions-newreview) {
  text-decoration: none;
  border-bottom: 1px solid;
  border-bottom-color: #00000026;
  position: relative;
  display: inline-block;
}

.rte a {
  color: #000000;
}

.rte-setting a:not(.btn):not(.spr-summary-actions-newreview):after,
.rte a:not(.btn):not(.spr-summary-actions-newreview):after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0%;
  border-bottom: 2px solid currentColor;
  transition: width 0.5s ease;
}

.rte-setting a:not(.btn):focus:after,
.rte-setting a:not(.btn):hover:after,
.rte a:not(.btn):focus:after,
.rte a:not(.btn):hover:after {
  width: 100%;
}

.rte a:hover {
  border-bottom: 1px solid;
  border-bottom-color: #000000;
}

.rte p {
  margin: 0 0 15px;
}

@media screen and (max-width: 800px) {
  .loginHeader {
    height: auto;
    /* background-color: transparent; */
  }

  .loginHeader [class*="Header_logo"] {
    display: block;
    width: 174px;
    height: auto;
    margin: 50px auto 0;
  }

  .loginHeader [class*="Header_mobileUserBar"] {
    display: none;
  }
}
