.container {
  display: grid;
  place-items: center;
  padding-top: 100px;
}

.content {
  text-align: center;
}

.content .tips {
  margin: 0;
  font-size: var(--font-0);
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
  color: rgb(0 0 0 / 50%);
}

.content .tips i {
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
}

.content .tips strong {
  display: block;
  margin: 25px auto 50px;
  font-size: 22px;
  font-weight: 600;
  color: var(--color-black);
}

.navigateButton {
  display: inline-block;
  margin: 50px auto;
  font-weight: 600;
  font-size: var(--font-4) !important;
  color: var(--color-black);
}

.newRequestGuide {
  display: none;
  position: fixed;
  left: 78px;
  bottom: 76px;
  font-size: var(--font-4);
  font-weight: 600;
  line-height: 16.42px;
  color: rgb(0 0 0 / 50%);
  text-align: left;
  padding-left: 36px;
  background: url(../../../../resources/images/arrow.svg) 0 16px no-repeat;
  background-size: 26px 13px;
}

@media screen and (max-width: 800px) {
  .container {
    padding-top: 60px;
  }

  .content .tips {
    font-size: var(--font-4);
  }

  .content .tips strong {
    font-size: 14px;
  }

  .hideOnMobile {
    display: none;
  }

  .newRequestGuide {
    display: block;
  }
}
