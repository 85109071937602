.detail {
  width: 675px;
  margin: auto;
  padding-top: 40px;
}

@media screen and (max-width: 800px) {
  .detail {
    width: auto;
    margin: 0;
    padding-top: 0;
  }

  :global .page-pick-request-detail [class*="Container_mainBg"] {
    padding-bottom: 64px;
  }

  :global .page-pick-request-detail [class*="Header_headerView"] {
    display: none;
  }

  :global .page-pick-request-detail [class*="Header_headerPlaceHolder"] {
    display: none;
  }
}
