.main {
  width: 100%;
  text-align: center;
  margin: 45px 0 10px 0;
}
.title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.sites {
  width: 400px;
  margin: 10px auto;
}

.sitesSearch {
  border: 1px solid #999999;
  width: 318px;
  height: 32px;
}
.search {
  color: #999999;
}
.sitesList {
  margin: 19px;
}
.sitesList li {
  list-style: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height, or 20px */

  text-decoration-line: underline;

  color: #000000;
  text-align: center;
  line-height: 2;
}
