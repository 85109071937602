.newRequestMain {
}

.newRequestTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  padding: 60px 0 20px;
}

.newRequestSubTitle {
  font-size: 16px;
  text-align: center;
  margin-bottom: 40px;
}

.Or {
  font-size: 22px;
  text-align: center;
}

.chooseTabs {
  width: 100%;
}

.tab {
  width: 670px;
  height: 125px;
  text-align: center;
  line-height: 125px;

  /* 1st Elem/Background */

  background: #ffffff;
  border-radius: 10px;
  margin: 20px auto;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
}

.tab :global .iconfont {
  font-size: 24px;
}

@media screen and (max-width: 800px) {
  .newRequestTitle {
    font-weight: 600;
    padding: 77px 0 15px;
    margin: 0 auto;
    font-size: 22px;
  }

  .newRequestTitle > span {
    display: block;
  }

  .newRequestSubTitle {
    margin-bottom: 50px;
  }

  .chooseTabs {
    padding: 0 10px;
  }

  .tab {
    display: block;
    width: 100%;
  }

  :global .page-pick-new-request [class*="Header_headerView"] {
    display: none;
  }

  :global .page-pick-new-request [class*="Header_headerPlaceHolder"] {
    display: none;
  }
}
