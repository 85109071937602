.SearchBarMain {
  padding: 0 0 0 35px;
  display: inline-block;
}

.search,
.sortBy {
  display: inline-block;
  margin-right: 20px;
}

.search .title,
.sortBy .title {
  font-size: 16px;
  font-weight: 600;
  padding-right: 13px;
}

.search > input {
  width: 250px;
}

.sortBy :global .ant-select .ant-select-selector {
  height: auto;
}

:global(.ant-select-selection-search) {
  border-bottom: 1px solid;
  color: black;
}

:global(.ant-select-selection-item) {
  font-size: 16px;
}

:global(.ant-select-arrow .anticon) {
  color: black;
}

@media screen and (max-width: 800px) {
  .SearchBarMain {
    display: none;
  }
}
