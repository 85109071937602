.tagsMain {
  /* width: 98%; */
  padding: 20px 0 0 25px;
  overflow-x: auto;
  width: max-content;
  line-height: 30px;
}

.tagsMain .ant-tag {
  border: none;
  border-radius: 99px;
  font-size: 14px;
  padding: 8px 14px;
  cursor: pointer;
  margin: 5px;
}

.tagsMain .ant-tag.active {
  background: var(--color-primary);
}

.tagsMain .mobileSearch {
  margin: 5px;
  display: none;
  transition: all 0.5s;
}

.tagsMain .mobileSearchIpt {
  display: none;
  position: relative;
  transition: all 0.5s;
}

.tagsMain .mobileSearchIpt input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 16px 11px 11px;
  position: static;
  width: 220px;
  height: 36px;

  /* right: 145px;
  top: 1px; */

  background: #f8fbe9;
  border: 1px solid #a6cf03;
  box-sizing: border-box;
  border-radius: 99px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 8px;
  padding: 0 35px;
}

.iptSearchIcon,
.iptClearIcon {
  position: absolute;
}

.iptSearchIcon {
  left: 19px;
  top: 3px;
  font-weight: bold;
}

.iptClearIcon {
  right: 19px;
  top: 3px;
  font-weight: bold;
  color: gray;
}

@media screen and (max-width: 800px) {
  .tagsMain {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 8px;
    height: auto;
  }

  .tagsMain .mobileSearch {
    margin: 0;
    display: inline-block;
    vertical-align: baseline;
  }

  .tagsMain .mobileSearchIpt {
    display: inline-block;
  }

  .tagsMain .mobileSearchIpt input:focus,
  .tagsMain .mobileSearchIpt input:focus-visible {
    outline: none;
    box-shadow: none;
  }

  .tagsMain .ant-tag {
    background: var(--color-white);
    border: 1px solid #d9d9d9;
  }

  .tagsMain * + .ant-tag {
    margin: 0;
    margin-left: 8px;
  }
}
