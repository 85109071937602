.searchView {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.searchBar .shareBtn {
  width: 36px;
  height: 36px;
  line-height: 34px;
  min-width: 36px;
  padding: 0;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 26px;
}

@media screen and (max-width: 800px) {
  .actions {
    display: none;
  }
}
