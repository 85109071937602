.black {
  background-color: var(--color-black);
  color: var(--color-white);
  height: 40px;

  border: var(--color-black);
}

.white {
  background-color: var(--color-white);
  color: var(--color-black);
  border: solid 1px var(--color-black);
  height: 40px;
}

.green {
  background-color: var(--color-primary);
  color: var(--color-black);
  height: 40px;
}

.black:hover,
.black:active,
.black:focus {
  background-color: #333333;
  color: white;
  border-color: #333333;
}
