.wrapper :global .iconfont {
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
}

.wrapper :global .ant-modal {
  width: 402px !important;
  top: 100px;
}

.wrapper :global .ant-modal-content {
  border-radius: 10px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
}

.wrapper :global .ant-modal-content .ant-modal-header {
  padding: 8px 10px 8px 39px;
  border-color: transparent;
  border-radius: 10px 10px 0 0;
  background: url(../../../resources/images/dialog-icon.png) 10px center / 21px
    20px no-repeat #f8fbe9;
}

.wrapper :global .ant-modal-content .ant-modal-title {
  color: var(--color-black);
  line-height: 20px;
  font-weight: 600;
}

.wrapper :global .ant-modal-content .ant-modal-close {
  color: var(--color-black);
}

.wrapper :global .ant-modal-content .ant-modal-close-x {
  width: 38px;
  height: 38px;
  line-height: 38px;
  font-size: 24px;
}

.wrapper :global .ant-modal .ant-modal-content .ant-modal-body {
  padding: 30px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.cover {
  position: relative;
  width: 240px;
  background: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.coverHeader {
  padding: 14px 10px;
}

.shapes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
}

.shapes > * {
  display: block;
  background: var(--color-gray5);
}

.shapesCircle {
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.shapesBar {
  width: 99px;
  height: 9px;
}

.coverImage {
  width: 100%;
  height: 258px;
}

.coverImage.multipleImages {
  height: 218px;
  overflow: hidden;
}

.coverImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: var(--color-gray5);
}

.urlImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 0.333em;
  font-size: var(--font-6);
  font-weight: 600;
  color: var(--color-black);
  background: #e7e7e7;
  user-select: none;
}

.urlImage :global .iconfont {
  font-weight: inherit;
}

.coverImageList {
  display: grid;
  padding: 0 9px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 89px);
  gap: 7px;
}

.coverImageItem {
  background: var(--color-gray5);
}

.coverActions {
  position: absolute;
  width: 100%;
  padding: 10px;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.coverDownloadBtn {
  width: 36px;
  height: 36px;
  padding: 0;
  font-size: 20px;
  border-color: var(--color-gray5);
  border-radius: 8px;
}

.coverFooter {
  padding: 8px 12px;
  line-height: 14px;
}

.coverFooter :is(h3, p) {
  color: var(--color-black);
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.coverFooter h3 {
  font-weight: 600;
  margin-bottom: 4px;
}

.coverFooter p {
  font-weight: normal;
  margin-bottom: 0;
}

.footer {
  margin-top: 80px;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
}

.actionsItem {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 56px;
  gap: 8px;
  text-align: center;
}

.actionsItem > .actionBtn {
  width: 56px;
  height: 56px;
  padding: 0;
  font-size: 20px;
  border-radius: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.06);
  color: #ffffff;
  border-color: transparent;
}

.actionBtn.copyBtn {
  background: rgba(0, 0, 0, 0.7);
}

.actionBtn.downloadBtn {
  background: #a6cf03;
}

.actionBtn.sendBtn {
  background: #7b61ff;
}

.actionsItem > span {
  width: 66px;
  height: 26px;
  font-size: 12px;
  font-weight: 600;
  line-height: 26px;
  color: var(--color-black);
  transform: scale(0.83);
}

.actionBtn.downloadBtn + span {
  line-height: 14px;
}

.actionsItem > .copiedMessage {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 56px;
  height: 56px;
  line-height: 16px;
  font-size: 12px;
  color: var(--color-primary);
  transform: none;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.8);
  user-select: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.actionsItem > .copiedMessage :global .iconfont {
  font-size: 16px;
}

.copiedMessage.showing {
  display: flex;
}

.description {
  margin-top: 24px;
  font-size: 14px;
  line-height: 16.42px;
  font-weight: normal;
  text-align: center;
  color: var(--color-black);
}

@media screen and (max-width: 800px) {
  .wrapper :global .ant-modal {
    width: 100vw !important;
    max-width: 100vw;
    margin: 0;
    padding: 0;
    top: 0;
  }

  .wrapper :global .ant-modal-content .ant-modal-header {
    display: none;
  }

  .wrapper :global .ant-modal-content .ant-modal-close {
    display: none;
  }

  .wrapper :global .ant-modal-content {
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    pointer-events: none;
  }

  .wrapper :global .ant-modal .ant-modal-content .ant-modal-body {
    padding: 0;
  }

  .container {
    height: 100vh;
  }

  .cover {
    top: calc((100vh - 265px) / 2);
    transform: translateY(-50%);
    pointer-events: auto;
  }

  .coverFooter h3 {
    font-weight: normal;
  }

  .footer {
    margin-top: 0;
    width: 100vw;
    padding: 20px 20px 52px;
    background: #f5f5f5;
    pointer-events: auto;
  }

  .footer::before {
    display: block;
    content: "";
    width: 57px;
    height: 6px;
    margin: 0 auto 24px;
    border-radius: 99px;
    user-select: none;
    background: rgba(0, 0, 0, 0.1);
  }

  .actionsItem > span {
    font-weight: normal;
  }
}
