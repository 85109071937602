.itemView {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  cursor: pointer;
  text-decoration: none;
}

.imageWrapper {
  display: block;
  width: 100%;
}

.productImage {
  width: 100%;
  height: 375px;
  object-fit: cover;
  object-position: 50% 0;
}

.itemContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;
  padding: 8px;
  flex: 1;
}

.subTitle {
  font-size: 14px;
  line-height: 1.4;
}

.price {
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  .productImage {
    height: 218px;
  }
}
