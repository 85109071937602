.container {
  min-height: 100vh;
  background: var(--color-gray5);
}

.container :global .iconfont {
  font-size: 1em;
}

.container :global .icon-thumbup {
  color: #a6cf03;
}

.container :global .icon-thumbdown {
  color: #ff5050;
}

.loading {
  display: block;
  position: relative;
  margin: 0 auto;
  top: 200px;
}

.scrollLoading {
  display: block;
  margin: 16px auto;
}

.empty {
  padding-top: 200px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  padding: 0 48px;
  background: var(--color-white);
}

.userInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.userInfo :global .ant-avatar {
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 22px;
}

.userInfo :global .ant-avatar .iconfont {
  font-weight: bold;
}

.userInfo h1 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 600;
  color: var(--color-black);
  margin: 0;
}

.description {
  font-size: 14px;
  line-height: 1.4;
  padding: 16px 48px 0;
}

.description > h2 {
  margin: 0 auto 10px;
  font-size: 16px;
  font-weight: 600;
}

.description > h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.description > p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}

.imageList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-auto-rows: min-content;
  gap: 40px 20px;
  padding: 70px 40px 40px;
}

.imageItem {
  position: relative;
}

.imageWrapper {
  width: 100%;
  height: 248px;
  padding: 0;
  background: var(--color-gray5);
}
.imageWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: var(--color-gray5);
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1px;
  height: 40px;
  margin-top: 10px;
}

.actions :global .ant-btn {
  height: 100%;
  border-color: transparent;
  border-radius: 0;
  font-size: 16px;
  box-shadow: none;
}

.likeBtn {
  color: #a6cf03 !important;
}

.unlikeBtn {
  color: #ff5050 !important;
}

.voteMessage {
  /* display: none; */
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: 14px;
  color: var(--color-black);
  font-weight: 600;
  text-align: center;
  background: var(--color-white);
  transition: all 0.3s ease;

  line-height: 40px;
}

.voted .voteMessage {
  display: flex;
}

.voteMessage :global .iconfont {
  position: absolute;
  left: 29px;
  font-size: 16px;
}

.image {
  width: 310px !important;
  height: 260px !important;
  object-fit: cover;
  object-position: center;
  background: var(--color-gray5);
}

@media screen and (max-width: 800px) {
  .imageWrapper {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
  .image {
    width: 100% !important;
    height: auto !important;
  }
  .container {
    padding-bottom: 8px;
  }

  .header {
    padding: 0 8px;
  }

  .description {
    padding: 16px 8px 0;
  }

  .imageList {
    grid-template-columns: repeat(auto-fill, minmax(148px, 1fr));
    gap: 8px;
    padding: 30px 8px 8px;
  }

  .actions {
    margin-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
  }

  .voteMessage {
    font-size: 12px;
    padding: 0 8px;
  }

  .voteMessage :global .iconfont {
    left: 8px;
  }
}
