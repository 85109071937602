.productItem {
  display: inline-block;
  cursor: pointer;
  vertical-align: top;
}

.productItem img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: 50% 0;
  vertical-align: top;
}

.productItem:hover .btns {
  display: block;
}

.productItem:hover .modelMainItem {
  opacity: 0.65;
}

.modelMain {
  position: relative;
}

.modelMainItem {
  position: relative;
  transition: all 0.2s;
  border-radius: 10px;
}

.btns {
  z-index: 9;
  display: none;
}

.move,
.delete,
.requset,
.requseted,
.mobileRequset,
.mobileRequseted {
  position: absolute !important;
  z-index: 9;
  border: none;
}

.move {
  top: 8px;
  left: 8px;
}

.delete {
  top: 8px;
  right: 8px;
}

.delete :global(.icon-trash) {
  font-size: 24px !important;
  line-height: 30px;
}

.requset,
.requseted {
  display: block;
  bottom: 8px;
  left: 8px;
  right: 8px;
  width: calc(100% - 16px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.requestedTipsBtn {
  display: block;
  bottom: 8px;
  left: 63px;
  right: 63px;

  position: absolute;
  width: 104px;
  height: 26px;
  /* background: rgba(0, 0, 0, 0.8); */
  /* Translucent Button */
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12); */
  /* backdrop-filter: blur(4px); */
  line-height: 1;

  border-radius: 99px;
  color: #ffffff;
  font-size: 13px;

  padding: 0 10px;
}

.requestedTips {
  display: block;
}
.requestedTipsBtnIcon {
  font-size: 12px;
  padding-right: 3px;
}
.mobileRequset {
  display: none;
  bottom: 8px;
  right: 8px;
  color: var(--color-black);
  background: rgb(255 255 255 / 75%);
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
}

.mobileRequseted {
  display: none;
  bottom: 8px;
  right: 8px;
  color: var(--color-white);
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
}

.iconfont .icon-shanchu {
  font-size: 13px !important;
}

.likeMain {
  text-align: left;
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.like,
.unlike {
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  margin-right: 10px;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .productItem img {
    width: 100%;
    margin: 0;
  }

  .btns {
    display: block;
  }

  .likeMain {
    display: none;
  }

  .move,
  .delete,
  .requset,
  .requseted,
  .requestedTips {
    display: none;
  }

  .mobileRequset,
  .mobileRequseted {
    display: block;
  }
}
