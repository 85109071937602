.productsView {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-auto-rows: max-content;
  gap: 20px;
  padding: 30px 40px 40px;
}

@media screen and (max-width: 800px) {
  .productsView {
    grid-template-columns: repeat(auto-fill, minmax(148px, 1fr));
    gap: 8px;
    padding: 30px 8px 40px;
  }
}
