/* form  */
.LoginForm {
  background: linear-gradient(270deg, #cee46a 0%, rgba(206, 228, 106, 0.5) 166%),
    #ffffff;
  height: calc(80vh - 53px);

  position: relative;
}

.loginContainer {
  /* background: saddlebrown; */
  height: 50%;
  width: 50%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.loginTitle {
  font-size: 30px;
  color: black;
  font-weight: bold;
  text-align: center;
  width: 330px;
  margin: auto;
}
.loginSection {
  text-align: center;
}

.handleBtnBox {
  margin: 40px 0 40px 0;
}
.handleBtnBoxStep2 {
  margin-bottom: 0;
}
.input {
  /* text-align: center; */
  padding-left: 50px;
  font-size: 26px;
  /* width: 300px; */
}

.loginInput,
.codeInput {
  width: 320px;
  text-align: center;
  margin: 40px auto;
  border: 3px solid #333333;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.step2Title {
  font-size: 16px;
  margin: 10px 0 10px 0;
}
.codeInput {
  margin: 5px auto 40px auto;
}
.codeInput input {
  text-align: center;
  font-size: 26px;
  align-items: center;
  letter-spacing: 0.4em;
  color: black;
}
.codeInput input::placeholder {
  color: #bfbfbf;
}

.resendBox {
  display: flex;
  text-align: center;
  margin: 20px auto 0 auto;
  width: 300px;
  padding-bottom: 20px;
}

.resend,
.back {
  flex: 1;
  cursor: pointer;
}

.userName {
  margin-bottom: 10px;
}
.passWord {
  margin-top: 0px;
}

/* footer  */
.FooterMain {
  background: #2f2f2f;
  min-height: 20vh;
}

.desc {
  /* width: 80%; */
  height: 100%;
  /* border: 1px solid saddlebrown; */
  color: white;

  padding: 20px 50px;
  width: 686px;
  margin-left: 11%;
}

.desc_t1 {
  font-size: 25px;
  font-weight: 500;
}

.desc_t2 {
  font-size: 14px;
}

.downLoadBtn {
  background: #cee46a;
  border-radius: 4px;
  color: black;
  font-size: 14px;
  border-color: #cee46a;
  margin-top: 12px;
}

.codeSending {
  color: gray;
}

.nicknameContainer {
  padding: 0 11px 11px 11px;
}

.nickNameSubmit {
  text-align: center;
  margin: 20px 0 0;
}

.nicknameTit {
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .LoginForm {
    min-height: calc(100vh);
  }

  .loginContainer {
    width: 100%;
  }
}
