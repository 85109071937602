.progressbarView {
  border-top: solid 2px var(--color-gray1);
  margin-top: 20px;
}

.stepItem {
  width: 30%;
  justify-content: center;
  align-items: center;
  margin-top: -6px;
}

.bullet {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 2px solid var(--color-white);
  background-color: var(--color-gray1);
}

.stepItem:first-of-type {
  align-items: flex-start;
}

.stepItem:last-of-type {
  align-items: flex-end;
}

.label {
  height: 40px;
  line-height: 40px;
}

.active {
  color: var(--color-primary);
}

.active_bullet {
  background: var(--color-primary);
}

.statusbarView {
  border-radius: 10px;

  /* height: 70px; */
  background-color: var(--color-white);
  padding: 15px;
  margin-top: 10px;
}

.chatIcon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: solid 1px var(--color-gray4);
  border-radius: 20px;
  color: var(--color-gray4);
  font-size: 24px;
  cursor: pointer;
}

.similarPrdView {
  margin-top: 20px;
}

.similarPrdItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  background: var(--color-gray5);
}

.similarPrdImgView {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(147px, 1fr));
  grid-auto-rows: min-content;
  gap: 15px;
  margin-top: 15px;
}

.similarPrdImage {
  width: 100%;
  height: 189px;
  object-fit: cover;
  background: var(--color-gray5);
}

.similarPrdItem :global .ant-carousel .slick-arrow {
  margin-top: -15px;
}

.similarPrdItem :global .ant-carousel .ant-image {
  width: 100%;
}

.similarPrdItemFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  grid-gap: 8px;
  padding: 8px;
  background: var(--color-white);
}

.requestFormView {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.requestFormView .item {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.requestFormView .item span {
  display: block;
  width: 48%;
  word-break: break-all;
}

.btnIcon {
  margin-left: 3px;
}

.productInfoView {
}

.productInfoView > div {
  width: calc(50% - 10px);
}

.btnShare {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border: solid 1px var(--color-gray4);
  border-radius: 18px;
  font-size: 20px;
}

.imagesView {
  position: relative;
  margin-top: 10px;
  height: 300px;
}

.imagesView .slideImageView {
  position: absolute;
  width: 100%;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  margin-top: 138px;
  padding: 15px;
}

.imagesView:hover .slideImageView {
  display: flex;
}

.imagesView img {
  cursor: pointer;
  position: absolute;
  width: 300px;
  height: 300px;
  object-fit: cover;
  left: 0;
  top: 0;
}

.btnSlide {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 12px;
  background-color: var(--color-white);
  font-size: 14px;
  cursor: pointer;
}

.tagView {
  justify-content: flex-start;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  border-radius: 5px;
  background-color: var(--color-second);
  padding: 2px 10px;
  margin-right: 5px;
  margin-top: 5px;
  font-size: 12px;
  white-space: nowrap;
}

.notesView {
  margin-top: 15px;
}

.pickedTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 3;
}

.RequestBtn {
  /* margin-top: 4px; */
}

.requsetBasic {
  font-weight: 500;
}

.processTag,
.successTag,
.failedTag {
  display: inline-block;
  border-radius: 8px;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: bold;
}

.processTag {
  background: rgb(0 0 0 / 5%);
  color: rgb(0 0 0 / 50%);
  margin-bottom: 6px;
}

.successTag {
  color: #7a9510;
  background: rgb(206 228 106 / 20%);
}

.failedTag {
  background: rgb(255 80 80 / 20%);
  color: #b70f0f;
}

.urlsDom {
  padding: 8px;
  width: 293px;
  height: 40px;
  background: rgb(0 0 0 / 2%);
  border: 1px solid rgb(0 0 0 / 10%);
  border-radius: 10px;
  margin: 5px 0;
  align-items: center;
}

.urlsDom a {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  font-weight: 500;
  width: 246px;
}

.urlsDom span {
  background-color: #e6e6e6;
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: block;
  margin-right: 5px;
  text-align: center;
}

.btn {
  font-weight: bold;
  margin-right: 15px;
}

.btnsBox {
  /* padding-top: 16px; */
}

.failedBullet {
  /* background: #ff5050; */
}

.failedLabel {
  /* color: #ff5050; */
}

.similarPrdCur {
  padding-top: 4px;
}

.requestBasic {
  /* font-weight: bold; */
  font-size: 12px;
}

.buttonMask {
  display: none;
  position: fixed;
  width: 100%;
  height: 64px;
  left: 0;
  bottom: 0;
  z-index: 1000;
  background: linear-gradient(180deg, #f5f5f5 0%, #ffffff 100%);
  box-shadow: 0 2px 10px rgb(0 0 0 / 6%);
}

/* drops for you   */
.dropsTit1 {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
}
.dropsTit2 {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.dropsList {
  text-align: left;
}

.dropsItem {
  padding: 0px;
  width: 147px;
  /* height: 245px; */
  background: #ffffff;

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin: 16px 10px 16px 0;

  text-align: center;
  display: inline-block;
}

.dropsImg {
  width: 147px;
  height: 205px;
  object-fit: cover;
  object-position: 50% 0;
}

.dropsBtn {
  width: 131px;
  height: 40px;
  background: #000000;
  border-radius: 99px;
  margin: 8px 0px;
}

.dropsView {
  border-radius: 10px;

  /* height: 70px; */
  background-color: var(--color-white);
  padding: 15px;
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  .container {
    margin: 0;
    padding: 8px;
  }

  .progressbarView {
    display: none;
  }

  .statusbarView {
    margin-top: 0;
  }

  .btnsBox {
    display: flex;
    padding-top: 0;
    padding-left: 10px;
    justify-content: center;
    align-items: center;
  }

  .buttonMask {
    display: block;
  }

  .btn,
  .RequestBtn {
    position: fixed;
    width: calc(100% - 16px);
    left: 8px;
    bottom: 12px;
    z-index: 1001;
  }

  .requestFormView .item {
    justify-content: space-between;
    gap: 20px;
  }

  .requestFormView .item span {
    font-size: 14px;
  }

  .requestFormView .item span:first-child {
    width: 50%;
  }
  .requestFormView .item span:last-child {
    flex: 1;
  }

  .productInfoView {
    flex-direction: column;
  }

  .productInfoView > div {
    width: auto;
    order: 2;
  }

  .productInfoView > .tagView {
    order: 1;
  }

  .productInfoView :global .ant-carousel > .slick-slider {
    width: auto !important;
  }
}
