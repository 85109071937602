@font-face {
  font-family: "iconfont"; /* Project id 2978321 */
  src: url('iconfont.woff2?t=1640316564913') format('woff2'),
       url('iconfont.woff?t=1640316564913') format('woff'),
       url('iconfont.ttf?t=1640316564913') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-copy:before {
  content: "\e6ae";
}

.icon-download:before {
  content: "\e6b2";
}

.icon-image:before {
  content: "\e63a";
}

.icon-link:before {
  content: "\e676";
}

.icon-plus:before {
  content: "\e603";
}

.icon-mobilecheck:before {
  content: "\e61b";
}

.icon-edit:before {
  content: "\e61a";
}

.icon-smallexit:before {
  content: "\e619";
}

.icon-search:before {
  content: "\e618";
}

.icon-exit:before {
  content: "\78";
}

.icon-exit-copy:before {
  content: "\e765";
}

.icon-arrowright:before {
  content: "\e616";
}

.icon-trash:before {
  content: "\e615";
}

.icon-move1:before {
  content: "\e614";
}

.icon-chat:before {
  content: "\e613";
}

.icon-thumbdownline:before {
  content: "\e612";
}

.icon-thumbupline:before {
  content: "\e611";
}

.icon-accountline:before {
  content: "\e610";
}

.icon-account:before {
  content: "\e60f";
}

.icon-thumbdown:before {
  content: "\e60e";
}

.icon-thumbup:before {
  content: "\e60a";
}

.icon-share1:before {
  content: "\e60d";
}

.icon-rightarrowsmall-copy:before {
  content: "\e762";
}

.icon-leftarrowsmall:before {
  content: "\e60b";
}

.icon-send:before {
  content: "\e606";
}

.icon-stop:before {
  content: "\e67f";
}

.icon-success:before {
  content: "\e680";
}

.icon-warning:before {
  content: "\e682";
}

.icon-apps:before {
  content: "\e729";
}

.icon-right:before {
  content: "\e605";
}

.icon-arrow-up:before {
  content: "\e743";
}

.icon-arrow-down:before {
  content: "\e763";
}

