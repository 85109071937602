.imageCover {
  width: 300px;
  height: 400px;
  object-fit: cover;
  object-position: 50% 0;
}

@media screen and (max-width: 800px) {
  .imageCover {
    width: 100%;
    height: auto;
  }
}
