.headerView {
  height: 53px;
  line-height: 53px;
  width: 100%;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 40px;
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0 2px 8px rgb(0 0 0 / 2%);
}

.headerPlaceHolder {
  height: 53px;
  width: 100%;
}

.logo {
  width: 137px;
  height: 46px;
  cursor: pointer;
  margin-right: 50px;
}

.leftView {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background: white;
}

.rightView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navItem {
  margin-right: 40px;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  text-align: left;
}

.navItem :global .ant-btn {
  height: 100%;
  padding: 0;
}

.active {
  border-bottom: solid 3px var(--color-primary);
}

.userIcon {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  border-radius: 50%;
  border: solid 1px var(--color-gray4);
}

.userNav {
  position: relative;
}

.dropDown {
  display: none;
  position: relative;
}

.userNav:hover .dropDown {
  display: block;
}

.dropDown:hover {
  display: block;
}

.mobileUserBar {
  display: none;
}

.shopHref {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.downLoadBtn {
  background: #cee46a;
  border-radius: 4px;
  color: black;
  font-size: 14px;
  border-color: #cee46a;
  margin-top: 12px;
}

@media screen and (max-width: 800px) {
  .logo,
  .userNav {
    display: none;
  }

  .headerPlaceHolder {
    height: 110px;
  }

  .rightView {
    display: unset;
    width: 100%;
    text-align: center;
  }

  .navItem {
    width: 34%;
    display: inline-block;
    text-align: center;
    margin-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .headerView {
    all: unset;
    width: 100%;
    padding: 0;
    background-color: var(--color-white);
    position: fixed;
    top: 0;
    z-index: 100;
    box-shadow: 0 2px 8px rgb(0 0 0 / 2%);
  }

  .mobileUserBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 8px;
    background: var(--color-white);
  }

  .mobileUserBarInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }

  .mobileUserBarInfo :global .ant-avatar {
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 22px;
    margin-right: 8px;
  }

  .mobileUserBarInfo :global .ant-avatar .iconfont {
    font-weight: bold;
  }

  .mobileUserBarInfo h1 {
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    color: var(--color-black);
    margin: 0;
  }

  .mobileUserBarActions .shareBtn {
    display: none;
    width: 36px;
    height: 36px;
    line-height: 34px;
    min-width: 36px;
    padding: 0;
  }

  :global(.show-header-share-button) .mobileUserBarActions .shareBtn {
    display: block;
  }

  .downLoadBtn {
    display: none;
  }
}
