.wrapper :global .iconfont {
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
}

.wrapper :global .ant-modal {
  width: 668px !important;
  top: 100px;
}

.wrapper :global .ant-modal-content {
  border-radius: 10px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
  background: var(--color-gray5);
}

.wrapper :global .ant-modal-content .ant-modal-header {
  padding: 16px 16px 12px;
  border-color: transparent;
  border-radius: 10px 10px 0 0;
  background: transparent;
}

.wrapper :global .ant-modal-content .ant-modal-title {
  font-size: var(--font-6);
  line-height: 1.2;
  font-weight: 600;
  color: var(--color-black);
}

.wrapper :global .ant-modal-content .ant-modal-close {
  color: var(--color-black);
}

.wrapper :global .ant-modal-content .ant-modal-close-x {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 24px;
}

.wrapper :global .ant-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

.container {
  display: block;
  padding: 0 16px 12px;
}

.container :global .ant-empty {
  margin: 50px 8px;
}

.productList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(147px, 1fr));
  grid-auto-rows: min-content;
  gap: 12px 16px;
}

.productItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  background: var(--color-gray5);
}

.image {
  width: 100%;
  height: 189px;
  object-fit: cover;
  background: var(--color-gray5);
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  grid-gap: 8px;
  padding: 8px;
  background: var(--color-white);
}

.meta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.metaItem {
  font-size: var(--font-4);
  line-height: 1.4;
  color: var(--color-black);
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.actions > a,
.actions :global(.ant-btn) {
  display: block;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .wrapper :global .ant-modal {
    width: calc(100vw - 16px) !important;
    max-width: calc(100vw - 16px) !important;
    margin: 8px !important;
    padding: 0;
    top: 0;
  }

  .wrapper :global .ant-modal-content {
    min-height: calc(100vh - 16px);
  }

  .container {
    padding: 0 8px 12px;
  }

  .productList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(147px, 1fr));
    grid-auto-rows: min-content;
    gap: 12px 8px;
  }
}
