.requestFormMain {
}

.productBasic {
  text-align: center;
}

.productBasic :global .ant-carousel {
  width: 124px;
  margin: 0 auto;
}

.productBasic :global .ant-carousel .slick-arrow {
  margin-top: -15px;
}

.productTitle {
  font-size: 18px;
  margin: 20px 0 5px;
}

.productImg {
  width: 124px !important;
  height: 124px !important;
  border-radius: 8px;
  object-fit: cover;
}

.price {
  font-weight: 500;
  font-size: 14px;
}

.quantityInput {
  width: 40%;
}

.quantityInput :global .ant-input-number {
  width: 100%;
}

.costInput {
  width: calc(100% - 120px) !important;
}

.submitBtnView {
  position: absolute;
  bottom: 20px;
  background-color: white;
  width: 100%;
}

/* requested  */
.requestedImg img {
  width: 90%;
  height: 300px;
  object-fit: cover;
  object-position: 50% 0;
  border-radius: 10px;
}

.requestedImg {
  position: relative;
}

.requestedIcon {
  position: absolute;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  background: #a6cf03;
  z-index: 9999;
  color: white;
  font-weight: bold;
  line-height: 30px;
  bottom: -14px;
  border-radius: 50%;
}
.requestedIcon2Box {
  position: relative;
  margin: 30px;
}
.requestedIcon2 {
  position: absolute;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  background: rgb(254, 81, 81);
  z-index: 9999;
  color: white;
  font-weight: bold;
  line-height: 30px;
  bottom: -14px;
  border-radius: 50%;
}
.requestdTit2 {
  padding-top: 24px;
}
.requestedBtn2 {
  width: 98%;
}

.requestedContainer {
  text-align: center;
}

.requestedBtn {
  width: 98%;
}

.requestdTit {
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  :global
    :is(.page-pick-list, .page-pick-request-detail)
    :is(.ant-modal-mask, .ant-modal-wrap) {
    z-index: 3000;
  }
  :global
    :is(.page-pick-list, .page-pick-request-detail)
    :is(.ant-select-dropdown) {
    z-index: 3050;
  }

  :global :is(.page-pick-list, .page-pick-request-detail) .ant-modal {
    max-width: 100vw;
    padding: 0;
    margin: 0;
    top: 0 !important;
  }

  :global
    :is(.page-pick-list, .page-pick-request-detail)
    .ant-modal
    .ant-modal-body {
    padding: 30px 8px 8px;
  }

  :global
    :is(.page-pick-list, .page-pick-request-detail)
    .ant-modal
    [class*="common_modalNav"] {
    text-align: center;
    background: transparent;
  }

  :global
    :is(.page-pick-list, .page-pick-request-detail)
    .ant-modal
    [class*="common_modalNaviImage"] {
    display: none !important;
  }

  :global
    :is(.page-pick-list, .page-pick-request-detail)
    .ant-modal
    .ant-input-group.ant-input-group-compact {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .orderTypeRow {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .orderTypeRow > :global(.ant-form-item) {
    width: 100% !important;
  }

  .submitBtn {
    width: 100%;
  }
}
