.mainAccountIndex {
  width: 100%;
  font-size: 16px;
  padding: 65px 0 23px;
}

.submit {
  text-align: center;
}

.mobileNavBar {
  display: none;
}

@media screen and (max-width: 800px) {
  .mainAccountIndex {
    padding: 0 8px 70px;
  }

  .mobileNavBar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0;
  }

  .mobileNavBar :global .ant-btn {
    padding: 0;
    line-height: 24px;
  }

  .mobileNavBar :global .iconfont {
    font-size: 24px;
    line-height: 24px;
  }

  :global .page-account [class*="Header_headerView"] {
    display: none;
  }

  :global .page-account [class*="Header_headerPlaceHolder"] {
    display: none;
  }

  .submit {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px 8px;
    background: linear-gradient(180deg, #f5f5f5 0%, #ffffff 100%);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
  }

  .submit :global .ant-btn {
    display: block;
    width: 100%;
    margin: 0;
  }
}
