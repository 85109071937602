.mainDropdown {
  position: absolute;
  background: #ffffff;

  /* Shadows/Element Shadow */

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  width: 272px;

  /* height: 268px; */
  right: 0;
  text-align: center;
  z-index: 999999;
}

.userPhoto {
  width: 74px;
  height: 74px;
  margin: 15px 0 0;
  border-radius: 50%;
}

.userBasic p {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 3px;
}

.divider {
  width: 85%;
  border-top: 1px solid gray;
  margin: auto;
}

.navbar {
  margin-bottom: 13px;
}

.navItem {
  list-style: none;
  text-align: left;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}

.navItem:hover {
  color: var(--color-primary);
}
