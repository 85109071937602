.requestFormMain .ant-select-selector {
  border: none !important;
}

/* .requestFormMain .ant-select-selector .ant-select-selection-item:nth-child(1) {
  font-size: 14px;
  font-weight: bold;
} */

.requestFormMain .ant-select-selection-search {
  border: none !important;
}

.requestFormMain .ant-select-selector {
  height: 50px !important;
  padding-top: 9px !important;
}

.requestFormMain .ant-radio-group {
  display: flex !important;
}

.requestFormMain .ant-radio-button-wrapper {
  flex: 1 !important;
  text-align: center;
  border: 2px solid white !important;
  height: 50px !important;
  line-height: 50px !important;
  margin-right: 5px;
}

.requestFormMain .ant-radio-button-wrapper-checked {
  border: 2px solid #000000 !important;
}

.requestFormMain .ant-checkbox + span {
  font-weight: 500;
}

.requestFormMain .ant-input,
.requestFormMain .ant-input-number-input {
  height: 50px !important;
}

/* upload Image  */
.uploadImagesMain .ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
}

.uploadImagesMain .ant-upload .ant-upload-select-picture-card {
  display: none;
}

.uploadImagesMain .ant-image-img {
  width: 99px;
  height: 99px;
  object-fit: cover;
  object-position: 50% 0;
  margin-right: 15px;
}

.uploadImagesMain .ant-upload-picture-card-wrapper {
  display: none;
}

/* urls */

.UrlsMain .ant-input {
  width: 90%;
  margin-left: 15px;
  margin-right: 10px;
}
