.itemView {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 10px;
  background-color: var(--color-white);
  cursor: pointer;
  overflow: hidden;
}

.productImage {
  width: 100%;
  height: 196px;
  object-fit: cover;
  object-position: 50% 0;
}

.itemContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 8px;
  flex: 1;
}

.subTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.infoText {
  font-size: var(--font-2);
  color: rgb(0 0 0 / 30%);
  line-height: 1.4;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusText {
  border-radius: 8px;
  font-size: 12px;
  text-align: right;
  background-color: var(--color-gray5);
  color: var(--color-gray3);
  padding: 5px 10px;
  text-transform: capitalize;
}

.fail {
  color: var(--color-danger);
  background-color: #f9d9d9;
}

.success {
  color: var(--color-primary);
  background-color: var(--color-lighter);
}

.btn {
  height: 40px;
  border-radius: 40px;
  line-height: 1.4;
  background: var(--color-black);
  color: var(--color-white);
  border: none;
  box-shadow: 0 2px 8px rgb(128 128 128 / 50%);
}

.btn:hover {
  border: none;
  color: var(--color-white);
  background: var(--color-gray1);
}

/* SearchBarMain */
.SearchBarMain {
  display: block;
  position: relative;
  width: 100%;
  min-width: 1050px;
  padding: 20px 40px 0;
}

.search,
.sortBy {
  display: inline-block;
  margin-right: 20px;
}

.search .title,
.sortBy .title {
  font-size: 16px;
  font-weight: 500;
  padding-right: 13px;
}

.search > input {
  width: 250px;
}

.search :global .icon-exit {
  display: none;
}

.iconSeach {
  font-size: 29px;
  vertical-align: middle;
}

.sortBy :global(.ant-radio-button-wrapper) {
  font-weight: 600 !important;
}

.sortBy :global(.ant-radio-button-wrapper-checked:hover) {
  color: var(--color-black);
}

.sortBy :global(.ant-select .ant-select-selector) {
  height: auto;
}

.sortBy :global(.ant-radio-button-wrapper-checked) {
  background-color: var(--color-primary);
}

.sortBy :global(.ant-select-selection-search) {
  border-bottom: 1px solid;
  color: black;
}

.sortBy :global(.ant-select-selection-item) {
  font-size: 16px;
}

.sortBy :global(.ant-select-arrow .anticon) {
  color: black;
}

.SearchBarMain :global .ant-radio-button-wrapper {
  border-color: transparent;
}

.SearchBarMain :global .ant-radio-button-wrapper-checked {
  background: var(--color-primary);
}

.SearchBarMain :global .ant-radio-button-wrapper-checked:hover {
  color: var(--color-black);
}

.productSourced {
  position: absolute;
  right: 40px;
  top: 34px;
}

.URLs {
  width: 100%;
  height: 196px;
  line-height: 196px;
  text-align: center;
  font-size: var(--font-6);
  font-weight: 600;
  background: #e7e7e7;
  color: var(--color-black);
}

.empty {
  width: 100%;
  background-color: rgb(255 255 255 / 50%);
  padding: 15px;
  color: var(--color-gray1);
  text-align: center;
  margin-top: 10px;
}

@media screen and (max-width: 800px) {
  .SearchBarMain {
    padding: 16px 8px 0;
    min-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling-x: touch;
  }

  .SearchBarMain::-webkit-scrollbar {
    display: none;
  }

  .search,
  .sortBy {
    margin-right: 8px;
  }

  .search {
    position: relative;
  }

  .search .title {
    position: absolute;
    padding: 0;
    margin: 0;
    z-index: 10;
    left: 8px;
    top: 50%;
    transform: translateY(-56%);
    width: 16px;
    height: 16px;
    line-height: 16px;
  }

  .search :global .iconfont {
    font-size: 16px;
  }

  .search :global .icon-exit {
    position: absolute;
    z-index: 10;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    line-height: 16px;
  }

  .search > input {
    width: 32px;
    height: 32px;
    border-radius: 100px;
    box-shadow: none;
  }

  .search.searchExpanded > input:focus {
    box-shadow: none;
  }

  .search > input::placeholder {
    color: transparent;
  }

  /* expanded state search input */
  .search.searchExpanded :global .icon-exit {
    display: block;
  }

  .search.searchExpanded > input {
    width: 220px;
    padding: 4px 32px;
    background: #f8fbe9;
    border: 1px solid #a6cf03;
  }

  .search.searchExpanded > input::placeholder {
    color: initial;
  }

  .sortBy .title {
    display: none;
  }

  .sortBy :global .ant-select {
    width: 92px !important;
  }

  .sortBy :global .ant-select .ant-select-selector {
    background-color: var(--color-white) !important;
    border-color: #d9d9d9 !important;
    border-radius: 100px;
  }

  .sortBy :global .ant-select-selection-search {
    border: none;
  }

  .sortBy :global .ant-radio-group {
    margin-top: 0 !important;
  }

  .sortBy :global .ant-radio-group .ant-radio-button-wrapper {
    margin-left: 8px;
  }

  .sortBy :global .ant-radio-group .ant-radio-button-wrapper:first-child {
    margin-left: 0;
  }

  .searchBarContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: max-content;
  }

  .productSourced {
    display: none;
  }

  .itemView {
    flex-direction: row;
  }

  .productImage,
  .URLs {
    width: 92px;
    height: 90px;
    line-height: 90px;
  }

  .itemContent {
    position: relative;
  }

  .subTitle {
    width: calc(100vw - 96px - 20px - 16px);
    font-size: var(--font-4);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .time {
    display: block;
    position: absolute;
    width: calc(100% - 120px);
    bottom: 9px;
    margin: 0;
  }

  .dateLabel {
    display: none;
  }

  .actionView {
    justify-content: flex-end;
  }

  .buttonWrapper {
    display: none;
  }
}
