.requestView {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));
  grid-auto-rows: 302px;
  gap: 16px;
  padding: 30px 40px 40px;
}

.title {
  width: 100%;
  font-weight: 500;
  margin-top: 20px;
}

.loading {
  width: 100%;
  height: 60px;
  background-color: white;
}

.mobileNewRequestBtn {
  display: none;
  width: 60px !important;
  height: 60px !important;
  position: fixed;
  left: 20px;
  bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
}

.mobileNewRequestBtn :global .iconfont {
  font-size: 20px;
  color: var(--color-black);
}

.emptyPage .title {
  display: none;
}

.emptyPage :global .ant-spin-nested-loading {
  display: none;
}

.emptyPage :global .ant-layout-footer {
  display: none;
}

@media screen and (max-width: 800px) {
  .requestView {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 90px;
    gap: 8px;
    padding: 16px 8px 8px;
  }

  .mobileNewRequestBtn {
    display: block;
  }
}
