:root {
  /* 主色调 */
  --color-primary: #cee46a;
  --color-primary-darker: #79950d;
  --color-second: #cee46a;
  --color-light: #deeba3;
  --color-lighter: #f8fbe9;
  --color-danger: #b70f0f;
  --color-warn: #ffd80b;

  /* 灰色调 */
  --color-black: #000000;
  --color-gray1: #333333;
  --color-gray2: rgb(183 183 183 / 35%);
  --color-gray3: #929292;
  --color-gray4: #bfbfbf;
  --color-gray5: #f2f2f2;
  --color-white: #ffffff;

  /* 文字大小 */
  --font-1: 10px;
  --font-2: 12px;
  --font-4: 14px;
  --font-6: 16px;
  --font-8: 18px;
  --font-0: 20px;
}
