@media screen and (max-width: 800px) {
  .loginContainer {
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;

    /* height: 100vh; */
  }

  .loginHeader {
    height: auto;
    background-color: transparent;
  }

  .loginHeader [class*="Header_logo"] {
    display: block;
    width: 174px;
    height: auto;
    margin: 50px auto 0px 35px;
  }

  .loginHeader [class*="Header_mobileUserBar"] {
    display: none;
  }

  .loginView {
    all: unset;
  }
}

@media screen and (max-width: 550px) {
  .loginContainer {
    height: 100vh;
  }
}
