.footer {
  background-color: transparent;
  margin-top: 30px;
  text-align: center;
  width: 100%;
}

.fixed {
  position: absolute;
  bottom: 30px;
}
