.SearchBarMain .ant-radio-button-wrapper {
  flex: 1 !important;
  text-align: center;
  margin-left: 10px;

  /* border: 2px solid white !important; */
  border-width: 1px;

  /* height: 45px !important; */
  border-radius: 99px;

  /* line-height: 45px; */
}

.SearchBarMain .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: var(--color-primary);
}

.SUCCESSReqItem {
  color: var(--color-primary) !important;
  background-color: var(--color-lighter) !important;
}

.FAILEDReqItem {
  color: var(--color-danger) !important;
  background-color: #f9d9d9 !important;
}
