.mainContent {
  width: 650px;
  margin: 0 auto;

  /* height: 800px; */

  /* border: 1px solid gray; */
}

.formTitle {
  text-align: left;
  font-weight: 600;
  padding: 60px 0 0px;
}

.cancelBtn,
.submitBtn {
  margin: 10px;
}

.costInput {
  width: calc(100% - 120px) !important;
}

/* upload Image  */
.uploadImagesMain {
  background: white;
  padding: 20px;
  /* padding-top: 50px; */
  position: relative;
  min-height: 80px;
  margin-bottom: 10px;
}

.uploadBtnHide {
  display: none;
}

.uploadBtnShow {
  position: absolute;
  display: block;
  width: 120px;
  text-align: right;
  height: 20px;
  right: 10px;
  top: 16px;
  cursor: pointer;
}

.uploadBtn {
  font-size: var(--font-2);
  border-radius: 4px;
  background-color: black;
  color: white;
  height: 25px;
  line-height: 15px;
}

.require {
  color: var(--color-danger);
}

.imgItem {
  position: relative;
  display: inline-block;
}

.imgItem:hover .removeIcon {
  display: inline-block;
}

.removeIcon {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
  z-index: 999;
  font-size: 16px;
  font-weight: bold;
  background: white;
  border-radius: 50%;

  /* display: inline-block; */
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  display: none;
}

.requestDone {
  text-align: center;
  padding: 70px 0 20px;
}

.mark {
  width: 358px;
  height: 335px;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto 30px;
  position: relative;
  background-color: #e6e6e6;
}

.mark img {
  width: 358px;
  height: 335px;
  object-fit: cover;
  object-position: 50% 0;
}

.resultImg {
  font-size: 100px;
  line-height: 335px;
  color: var(--color-gray1);
}

.doneIcon {
  position: absolute;
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  background: #a6cf03;
  z-index: 9999;
  color: white;
  font-weight: bold;
  line-height: 30px;
  bottom: -14px;
  border-radius: 50%;
}

.handleBtns {
  margin: 50px auto;
}

.reqBtn,
.moreBtn {
  width: 176px;
  height: 48px;
  border-radius: 99px;
  margin: 10px;
}

.reqBtn {
  color: white;
  background: #000000;
}

.moreBtn {
  color: black;
  background: #cee46a;
  border-color: #cee46a;
}

.errMsg {
  color: #ff4d4f;
  bottom: 0;
  position: absolute;
}

.UrlsMain {
  margin-bottom: 20px;
}

.urlIndex {
}

.urlItem {
  margin-bottom: 10px;
}

.addUrlBtn {
  width: 90%;
  margin-left: 23px;
  border-radius: 99px;
  border-color: rgb(220 223 230);
  height: 40px;

  /* line-height: 40px; */
}

.tip {
  color: rgb(146 146 146);
  font-size: 13px;
  text-align: center;
  margin: 10px 0;
}

.urlRemoveIcon {
  font-weight: bold;
  font-size: 19px;
  cursor: pointer;
}

.urlErrMsg {
  color: #ff4d4f;
  text-align: center;
}

.urlTextArea {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.uploadImagmesContent {
  width: 100%;
  height: 135px;
  border: 2px;
  border-style: dashed;
  border-radius: 10px;
  border-color: gainsboro;
  text-align: center;
  line-height: 130px;
  background: white;
  margin-bottom: 16px;
}

.dropText {
  font-size: var(--font-6);
  color: #000000;
  padding-right: 10px;
  font-weight: 500;
}

.imageEnter {
  background: #faffe3;
}

@media screen and (max-width: 800px) {
  .mainContent {
    width: 100%;
    padding: 24px 10px;
    margin: 0;
  }

  .formTitle {
    text-align: center;
    padding: 0;
    margin-bottom: 18px;
  }

  .uploadImagesMain {
    padding-bottom: 60px;
  }

  .uploadBtnShow {
    left: 50%;
    right: auto;
    top: auto;
    bottom: 30px;
    transform: translateX(-50%);
    z-index: 10;
  }

  .requestFormMain :global .ant-input-group {
    display: flex;
  }

  .requestFormMain :global .ant-input-group .ant-select {
    min-width: 100px;
  }

  .orderTypeRow {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .orderTypeRow > :global(.ant-form-item) {
    width: 100% !important;
  }

  .checkBoxRow :global .ant-form-item-control-input-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .cancelBtn {
    display: none;
  }

  .submitBtn {
    display: block;
    width: 66%;
    margin: 0 auto;
  }

  .handleBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 50px 10px;
    gap: 10px;
  }

  .reqBtn,
  .moreBtn {
    margin: 0;
  }
}
