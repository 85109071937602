.OrganizeBoardMain {
  padding: 20px 0 0 26px;
  display: inline-block;
  display: none;
}

.btnOrgnize {
  border-radius: 10px;
  height: 36px;
  width: 172px;
  display: flex;
  align-items: center;
}

.icon {
  font-size: 20px;
  margin-right: 10px;
}

@media screen and (max-width: 800px) {
  .OrganizeBoardMain {
    display: none;
  }
}
