.mainContent {
  width: 100%;

  /* min-width: 1000px; */
  margin: 10px auto 30px;
  padding: 25px;
  padding-top: 0;
  text-align: center;
  background-color: var(--color-gray5);
}

.productListView {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  grid-auto-rows: max-content;
  gap: 10px 20px;
}

.placeholder {
  width: 200px;
  height: 300px;
  margin: 10px;
}

.loading {
  width: 100%;
  height: 60px;
  background-color: white;
}

@media screen and (max-width: 800px) {
  .mainContent {
    padding: 0 8px;
  }

  .productListView {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}
