.mainProfile,
.mainLogins,
.mainNotifications {
  width: 670px;

  /* height: 230px; */
  background: #ffffff;
  border: 1px solid rgb(0 0 0 / 10%);
  border-radius: 10px;
  margin: 10px auto;
  padding-bottom: 10px;
}

.title {
  margin: 10px 15px;
  font-size: 22px;
}

.basicInfo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.photo,
.name {
  display: inline-block;

  /* background: saddlebrown; */
  height: 83%;
  margin-left: 10px;
  text-align: center;
  vertical-align: bottom;
}

.photo {
  width: 200px;
}

.name {
  width: 60%;
  padding-right: 20px;
}

.photoImg {
  width: 115px;
  height: 115px;
  margin: 10px 0;
  border-radius: 50%;
}

.userName {
  height: 115px;
  font-size: 18px;
  font-weight: 600;
  line-height: 115px;
  margin: 10px 0 20px;
}

.editPhoto,
.editName {
  margin: 10px;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s;
  color: black;
}

.editPhoto:hover,
.editName:hover {
  color: var(--color-primary);
}

.loginsForm {
  margin: 10px 15px;
}

.loginsFormItem {
  position: relative;
}

.loginFormItemVal {
  margin-top: 5px;
  font-weight: 500;
  font-size: 18px;
}

.editText {
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.notificationsFormItem {
  font-size: 16px;
  padding: 0 15px 10px;
}

.uploadHiddenDom {
  display: none;
}

.inputView {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.loginsBtn {
  border: 1px solid gainsboro;
  width: 370px;
  padding-left: 45px;
}

.verificatCode {
  margin-top: 12px;
}

.verificationBtn {
  margin: 0 12px;
}

@media screen and (max-width: 800px) {
  .mainProfile,
  .mainLogins,
  .mainNotifications {
    width: 100%;
    margin: 0 0 16px;
  }

  .basicInfo {
    flex-direction: column;
  }

  .photo,
  .name {
    margin: 0;
  }

  .name {
    height: auto;
    padding: 0;
    margin-top: 20px;
  }

  .userName {
    height: 34px;
    line-height: 34px;
    margin: 0;
  }

  .loginsBtn {
    width: 100%;
  }

  .verificatCode {
    width: calc(100% - 116px);
  }

  .verificationBtn {
    margin: 0 0 0 8px;
  }

  .inputView {
    justify-content: space-between;
  }
}
