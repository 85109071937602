@import "theme.css";

/**
 * 页面共享常用样式，必须使用驼峰式命名
 */
.boxShadow {
  box-shadow: 0 2px 8px rgb(128 128 128 / 10%);
}

.colorPrimary {
  color: var(--color-primary);
}

.colorSecond {
  color: var(--color-second);
}

.colorLight {
  color: var(--color-light);
}

.colorLighter {
  color: var(--color-lighter);
}

.colorDanger {
  color: var(--color-danger);
}

.colorWarn {
  color: var(--color-warn);
}

.colorBlack {
  color: var(--color-black);
}

.colorWhite {
  color: var(--color-white);
}

.colorGray1 {
  color: var(--color-gray1);
}

.colorGray2 {
  color: var(--color-gray2);
}

.colorGray3 {
  color: var(--color-gray3);
}

.colorGray4 {
  color: var(--color-gray4);
}

.tb {
  font-weight: 600;
}

.t0 {
  font-size: 20px;
}

.t8 {
  font-size: 18px;
}

.t6 {
  font-size: 16px;
}

.t4 {
  font-size: 14px;
}

.t2 {
  font-size: 12px;
}

.t1 {
  font-size: 10px;
}

.allCenter {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contentPadding {
  padding: 16px;
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content {
  width: 100%;
  position: relative;
}

.card {
  box-shadow: 0 2px 8px rgb(128 128 128 / 10%);
  border-radius: 10px;
  background-color: var(--color-white);
  padding: 15px;
  margin-top: 10px;
}

.nowrapText {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.backButton {
  position: absolute;
  left: 40px;
  top: 40px;
  width: 209px;
  height: 50px;
  font-size: var(--font-6);
  border-radius: 10px;
}

/* modal */
.modalNav {
  width: 100%;
  height: 40px;
  left: 0;
  top: 0;
  position: absolute;
  line-height: 40px;
  text-align: left;
  z-index: 999;
  background: rgb(248 251 233);
  border-radius: 10px;
}

.modalNaviImage {
  vertical-align: text-bottom;
  display: unset !important;
  width: 35px;
}

.modalNavTitle {
  display: inline-block;
  margin-left: 4px;
  font-weight: bold;
  font-size: 16px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 89%;
}

.modalNavClose {
  position: absolute;
  right: 10px;
  font-size: 20px;
}

.modalNavCloseIcon {
  font-size: 25px;
  cursor: pointer;
}

.labelVal {
  overflow: hidden;
  text-overflow: ellipsis;
  display: box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.submitBtn {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.imgHoverZoom {
  overflow: hidden;
}

.imgHoverZoom img {
  transition: transform 0.1s ease;
}

.imgHoverZoom:hover img {
  transform: scale(1.2);
  transition: transform 2s ease;
}
